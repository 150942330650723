<template>
    <modal ref="crearActividad" tamano="modal-md" titulo="Crear actividad" adicional="Crear" @adicional="crearActividad">
        <div class="my-3 mx-5">
            <p class="input-label-top">Nombre de la actividad</p>
            <el-input v-model="nombreActividad" class="w-100" size="small" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombreActividad: '',
        }
    },
    methods: {
        toggle(){
            this.nombreActividad = ''
            this.$refs.crearActividad.toggle()
        },
        crearActividad(){
            this.$emit('crearActividad', this.nombreActividad)
            this.$refs.crearActividad.toggle()
        },
    }
}
</script>

<style lang="css" scoped>
.g{
    width: 200px;
}
</style>
